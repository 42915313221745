.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.stage3D {
  flex-grow: 1;
}

.alert {
  flex-shrink: 0;
}